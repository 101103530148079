import './App.css';
import Plx from 'react-plx';

const Snowflake = ({ style }) => {
  return (
    <p className="snow-flake" style={style}>
      {'\u2745'}
    </p>
  );
};

const makeSnowFlakes = () => {
  let animationDelay = '0s'; // 기본 값은 0초이다.
  let fontSize = '14px'; // 기본 폰트사이즈는 14px로 했다.
  const arr = Array.from('Merry Christmas'); // length가 15인 array가 생긴다.

  // arr의 length 만큼의 <SnowFlake />를 반환한다.
  return arr.map((el, i) => {
    animationDelay = `${(Math.random() * 16).toFixed(2)}s`; // 0~16 사이에서 소수점 2번째 자리수까지의 랜덤숫자
    fontSize = `${Math.floor(Math.random() * 10) + 10}px`; // 10~20 사이의 정수
    const style = {
      animationDelay,
      fontSize,
    };
    return <Snowflake key={i} style={style} />;
  });
};

function App() {
  return (
    <div className="App">
      <div className="snow-container">{makeSnowFlakes()}</div>
      <div style={{ height: '50px' }}></div>
      <p className="Title">KSCY</p>
      <p className="SubTitle">2023 WINTER</p>
      <div style={{ height: '20px' }}></div>
      <p className="keywords">
        <span className="keyword">#질문</span>
        <span>#도전</span>
        <span>#리뉴얼</span>
        <span>#피드백</span>
        <span>#탐색</span>
        <span>#교류</span>
      </p>

      <div style={{ height: '50px' }}></div>
      <div className="timeContainer">
        <p className="timeText">2023. 01. 13 ~ 2023. 01. 15</p>
      </div>
      <div style={{ height: '50px' }}></div>
      <div className="timeContainer">
        <p className="timeText">연세대학교 국제 캠퍼스</p>
        <div style={{ height: '5px' }}></div>
        <p className="subText">인천 연수구 송도과학로 85</p>
      </div>

      <div style={{ height: '150px' }}></div>
      <p className="visionText">질문과 도전을 통해 누구나 혁신할 수 있는 세상!</p>

      <div style={{ height: '100px' }}></div>
      <div className="trackContainer">
        <div className="startupContainer">
          <p className="trackTitle">Startup Camp Track</p>
          <p>대상: 고등학생 또는 그에 준하는 연령</p>
          <p>
            실무에 대한 호기심과 창업에 대한 열정이 있는 청소년 혁신 기업가들이 Business 분야와 Tech 분야 중 선택하여
            창업에 필요한 직무 교육을 받습니다.
          </p>
        </div>
        <div className="researchContainer">
          <p className="trackTitle">Research Camp Track</p>
          <p>대상: 중/고등학생 또는 그에 준하는 연령</p>
          <p>
            학술점 호기심과 연구에 대한 열정있는 청소년 학자들이 연구를 시작함에 있어 도움을 받을 수 있도록 도와줍니다.
          </p>
        </div>
      </div>
      <div style={{ height: '100px' }}></div>
      <p className="LastText">정규 접수: ~ 2022년 12월 27일</p>
      <div style={{ height: '10px' }}></div>
      <a href="https://www.kscy.kr/" target="_blank" className="LastBtn">
        KSCY 사이트<br></br>바로가기
      </a>
      <div style={{ height: '80px' }}></div>
    </div>
  );
}

export default App;
